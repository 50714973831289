<template>
  <v-row class="fill-height" no-gutters>
    <v-col class="pa-8 pa-md-16" cols="12" md="7" xl="8">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="4" xl="3">
          <v-img class="mb-6" :src="require(`@/assets/logotype.svg`)" />
        </v-col>
      </v-row>

      <v-divider class="mb-6"></v-divider>

      <router-view />
    </v-col>

    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5" xl="4">
      <v-img
        :src="
          require(`@/assets/auth/${$route.name
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-')}.png`)
        "
        height="100%"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    if (this.$store.getters.authenticated) this.$router.push({ path: "/" });
  },
};
</script>

<style lang="scss" scoped></style>
